import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    .bodyFixed {
        overflow: hidden;
    }
    .w-100 {
        width: 100%;
    }
    .m-w-100 {
        max-width: 100%;
    }
    .spaced-grid-wrapper {
        height: auto;
        overflow: hidden;
    }
    .d-block {
        display: block;
    }
    .body-text-link {
        text-decoration: none;
    }
    .btn-reset {
        background: none;
        border: 0;
        font-family: inherit;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }
    input[type="textfield"] {
        -webkit-appearance: none;
    }
    input:-webkit-autofill {
        background: transparent !important;
    }
    .error {
        color: red;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .transition-elm {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        background-position: center;
        will-change: opacity;
    }
`;
