import React, { useState, useEffect, useContext } from 'react';
import Link from 'gatsby-link';
import { Context } from 'store/provider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContainerDefault } from 'components/containers';
import { Typography } from '@mui/material';
import { BtnDefault } from 'components/ui';
import { EnquiryForm } from 'components/forms';
import Brand from 'images/brand.svg';

function EnquiryOverlay() {
  const [opacityVal, setOpacityVal] = useState(0);
  const [zIndexVal, setZIndexVal] = useState(-10);
  const [state, dispatch] = useContext(Context);

  const toggleEnquiry = () => {
    dispatch({ type: 'SET_ENQUIRY_OVERLAY', payload: !state.enquiry_overlay });
  };

  useEffect(() => {
    if (state.enquiry_overlay) {
      setOpacityVal(1);
      setZIndexVal(100);
    } else {
      setOpacityVal(0);
      setZIndexVal(-10);
    }
  }, [state.enquiry_overlay]);

  return (
    <Box
      bgcolor="primary.main"
      sx={{
        position: 'fixed',
        zIndex: zIndexVal,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        opacity: opacityVal,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      <ContainerDefault>
        <Box py={6}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box
              component={Grid}
              item
              xs={3}
              display={{ xs: 'none', md: 'block' }}
            >
              <Link to="/">
                <Brand />
              </Link>
            </Box>
            <Box component={Grid} item display={{ xs: 'none', md: 'block' }}>
              <Typography component="p" variant="h1" color="text.white">
                Make an enquiry
              </Typography>
            </Box>
            <Box
              component={Grid}
              item
              display="flex"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              xs={12}
              md={3}
            >
              <Box
                className="btn-reset"
                component="button"
                onClick={() => toggleEnquiry()}
              >
                <BtnDefault data="Close" />
              </Box>
            </Box>
          </Grid>
          <Box>
            <EnquiryForm />
          </Box>
        </Box>
      </ContainerDefault>
    </Box>
  );
}

export default EnquiryOverlay;
