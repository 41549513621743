import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

function GridContainer(props) {
  const { children, alignItems, justifyContent, spacing } = props;
  const val = spacing ? spacing : 0;
  const justify = justifyContent ? justifyContent : 'center';
  return (
    <div className="spaced-grid-wrapper">
      <Grid
        container
        alignItems={alignItems}
        justifyContent={justify}
        spacing={val}
      >
        {children}
      </Grid>
    </div>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
};

export default GridContainer;
