import React from 'react';
import PropTypes from 'prop-types';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function RichTextField(props) {
  const { data } = props;
  return <RichText render={data} htmlSerializer={htmlSerializer} />;
}

RichTextField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RichTextField;
