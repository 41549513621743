import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function Spacer(props) {
  const { children, spacer_top, spacer_bottom } = props;
  return (
    <Box pt={spacer_top} pb={spacer_bottom} className="spacer">
      {children}
    </Box>
  );
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
  spacer_top: PropTypes.number,
  spacer_bottom: PropTypes.number,
};

export default Spacer;
