import React from 'react';
import PropTypes from 'prop-types';

function Image(props) {
  const { src, alt } = props;
  return <img className="w-100 d-block" src={src} alt={alt ? alt : ''} />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;
