import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ContainerDefault } from 'components/containers';
import { BtnWhiteBlock } from 'components/ui';
import Typography from '@mui/material/Typography';

function EnquiryForm() {
  const [state, handleSubmit] = useForm('moqrqabe');

  const CssTextField = styled(TextField)({
    width: '100%',
    '.MuiInput-root': {
      color: '#fff',
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#a3a3a3',
      },
    },
    '& label': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-root:before': {
      borderBottomColor: '#fff',
    },
  });

  if (state.succeeded) {
    return (
      <Box mt={10}>
        <Typography
          component="p"
          variant="h3"
          color="text.white"
          align="center"
        >
          Thanks for your enquiry! A member of our team will get back to you as
          soon as possible
        </Typography>
      </Box>
    );
  }

  return (
    <ContainerDefault maxWidth="md">
      <Box mt={10}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CssTextField
                label="Name"
                variant="standard"
                name="name"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <ValidationError
                className="error"
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={6}>
              <CssTextField
                label="Email"
                variant="standard"
                name="email"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <ValidationError
                className="error"
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={6}>
              <CssTextField
                label="Phone"
                variant="standard"
                name="phone"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <ValidationError
                className="error"
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField label="Company" variant="standard" name="company" />
              <ValidationError
                className="error"
                prefix="Company"
                field="company"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                label="Message"
                variant="standard"
                name="message"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <ValidationError
                className="error"
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </Grid>
            <Box
              component={Grid}
              item
              xs={12}
              display="flex"
              justifyContent="center"
            >
              <Box mt={5}>
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="btn-reset"
                >
                  <BtnWhiteBlock data="Send" />
                </button>
              </Box>
            </Box>
          </Grid>
        </form>
      </Box>
    </ContainerDefault>
  );
}

export default EnquiryForm;
