import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function BlockContainer(props) {
  const { children, paddingTop } = props;
  const paddingTopVal = paddingTop ? paddingTop : 15;
  return (
    <Box bgcolor="background.primary" pt={paddingTopVal} pb={15}>
      {children}
    </Box>
  );
}

BlockContainer.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.number,
};

export default BlockContainer;
