import * as React from 'react';
var PrismicDOM = require('prismic-dom');
import { Elements } from 'prismic-richtext';
import linkResolver from 'helpers/linkResolver';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const htmlSerializer = function(type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return (
        <Typography variant="h1" component="h1" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading2:
      return (
        <Typography variant="h2" component="h2" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading3:
      return (
        <Typography variant="h3" component="h3" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading4:
      return (
        <Typography variant="h4" component="h4" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading5:
      return (
        <Typography variant="h5" component="h5" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading6:
      return (
        <Typography variant="h6" component="h6" key={key}>
          {children}
        </Typography>
      );
    case Elements.paragraph:
      if (!children[0]) {
        return <br key={key} />;
      }
      return (
        <Typography variant="body1" component="p" gutterBottom key={key}>
          {children}
        </Typography>
      );
    case Elements.preformatted:
      return <pre key={key}>{children}</pre>;
    case Elements.strong:
      return <strong key={key}>{children}</strong>;
    case Elements.em:
      return <em key={key}>{children}</em>;
    case Elements.listItem:
      return (
        <Typography variant="body1" component="li" key={key}>
          {children}
        </Typography>
      );
    case Elements.oListItem:
      return (
        <Typography variant="body1" component="li" key={key}>
          {children}
        </Typography>
      );
    case Elements.list:
      return <ul key={key}>{children}</ul>;
    case Elements.oList:
      return <ol key={key}>{children}</ol>;
    case Elements.image:
      var linkUrl = element.linkTo
        ? PrismicDOM.Link.url(element.linkTo, linkResolver)
        : null;
      var linkTarget =
        element.linkTo && element.linkTo.target ? element.linkTo.target : '';
      var wrapperClassList = [element.label || '', 'block-img'];
      var img = (
        <img
          src={element.url}
          alt={element.alt || ''}
          copyright={element.copyright || ''}
        />
      );
      return (
        <div
          key={key}
          target={linkTarget}
          className={wrapperClassList.join(' ')}
        >
          {linkUrl ? (
            <a className="body-text-link" href={linkUrl}>
              {img}
            </a>
          ) : (
            img
          )}
        </div>
      );
    case Elements.embed:
      return (
        <div
          key={key}
          data-oembed={element.oembed.embed_url}
          data-oembed-type={element.oembed.type}
          data-oembed-provider={element.oembed.provider_name}
        >
          {element.oembed.html}
        </div>
      );
    case Elements.hyperlink:
      var target = element.data.target
        ? 'target="' + element.data.target + '" rel="noopener"'
        : '';
      linkUrl = PrismicDOM.Link.url(element.data, linkResolver);
      return (
        <Typography
          component="a"
          variant="button"
          key={key}
          className="body-text-link hyperlink"
          href={linkUrl}
        >
          {children}
        </Typography>
      );
    case Elements.label:
      var textColor = 'text.primary';
      var bgColor = 'transparent';
      var paddingVals = '0px 0px';
      if (element.data.label == 'text-white') {
        textColor = 'text.white';
      }
      if (element.data.label == 'text-blue') {
        textColor = 'text.secondary';
      }
      if (element.data.label == 'default-white-button') {
        textColor = 'text.secondary';
        bgColor = 'background.default';
        paddingVals = '10px 18px 10px 18px';
      }
      return (
        <Box
          key={key}
          component="span"
          className={element.data.label ? element.data.label : ''}
          sx={{
            color: textColor,
            backgroundColor: bgColor,
            padding: paddingVals,
          }}
        >
          {children}
        </Box>
      );
    case Elements.span:
      if (content) {
        return content.split('\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement(
              'br',
              propsWithUniqueKey({}, brIndex)
            );
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default htmlSerializer;
