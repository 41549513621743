import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function GradientOverlay(props) {
  const { gradient } = props;
  const background = gradient
    ? gradient
    : 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 100%)';
  return (
    <Box
      position="absolute"
      sx={{
        zIndex: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: background,
      }}
    ></Box>
  );
}

GradientOverlay.propTypes = {
  gradient: PropTypes.string,
};

export default GradientOverlay;
