import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import Head from 'components/head';
import GlobalStyle from 'global.css.js';
import Helmet from 'react-helmet';
import { EnquiryOverlay } from 'components/ui';

function Layout(props) {
  const { children } = props;
  const [state, dispatch] = useContext(Context);
  return (
    <div>
      <Helmet>
        <body className={`${state.enquiry_overlay ? 'bodyFixed' : 'body' }`} />
      </Helmet>
      <GlobalStyle />
      <Head />
      <EnquiryOverlay />
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
