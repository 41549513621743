import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import linkResolver from 'helpers/linkResolver';

function BtnWhiteBlock(props) {
  const { data, link } = props;

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <ConditionalWrapper
      condition={link ? link.uid : false}
      wrapper={children => (
        <Link
          to={linkResolver(link ? link : null)}
          style={{ textDecoration: 'none' }}
        >
          {children}
        </Link>
      )}
    >
      <Box
        component="div"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        px={5}
        py={1}
        sx={{
          border: `1px solid`,
          borderColor: 'text.white',
          backgroundColor: 'background.default',
        }}
      >
        <Typography component="span" variant="btnDefault" color="primary.main">
          {data}
        </Typography>
      </Box>
    </ConditionalWrapper>
  );
}

BtnWhiteBlock.propTypes = {
  data: PropTypes.node.isRequired,
  link: PropTypes.object,
};

export default BtnWhiteBlock;
