import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, ValidationError } from '@formspree/react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const formItems = [
  {
    type: 'textfield',
    label: 'Full Name',
    name: 'full_name',
    required: true,
  },
  {
    type: 'textfield',
    label: 'Mobile',
    name: 'mobile_number',
    required: true,
  },
  {
    type: 'textfield',
    label: 'What area do you live in?',
    name: 'what_area_do_you_live_in',
    required: true,
  },
  {
    type: 'select',
    label: 'Current position',
    name: 'current_position',
    options: [
      'Groundworker',
      'Machine Driver',
      'Labourer',
      'Site Manager',
      'Contracts Manager',
    ],
  },
  {
    type: 'select',
    label: 'Any relevant construction cards you hold or qualifications?',
    name: 'relevant_construction_cards',
    options: ['CPCS', 'CSCS', 'OTHER'],
  },
  {
    type: 'select',
    label: 'Do you have First aid experience?',
    name: 'first_aid_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have SSSTS experience?',
    name: 'sssts_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have SMSTS experience?',
    name: 'smsts_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have Streetworks experience?',
    name: 'streetworks_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'file',
    label:
      'Please upload a clear image showing both the front and back of your current cards',
    name: 'upload_current_cards',
  },
  {
    type: 'select',
    label: 'How many years experience do you have in construction?',
    name: 'years_experience_in_construction',
    options: [
      '0 - 1 years',
      '2 -5 years',
      '5 - 10 years',
      '10 - 15 years',
      '15 - 20 years',
      '20+ years',
    ],
  },
  {
    type: 'select',
    label: 'Do you have any experience working on new housing sites?',
    name: 'experience_working_on_new_housing_sites',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have confined space experience?',
    name: 'confined_space_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have front end experience?',
    name: 'front_end_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have finishing experience?',
    name: 'finishing_experience',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have an Abrasive Wheel certificate?',
    name: 'abrasive_wheel_certificate',
    options: ['Yes', 'No'],
  },
  {
    type: 'textfield',
    label: 'When does it expire?',
    name: 'when_does_it_expire',
    required: true,
    conditional: true,
    condition: {
      key: 'abrasive_wheel_certificate',
      value: 'Yes',
    },
  },
  {
    type: 'select',
    label: 'Does your role require using a STIHL saw?',
    name: 'role_require_using_a_STIHL_saw',
    options: ['Yes', 'No'],
  },
  {
    type: 'textfield',
    label: 'How long has it been since your last Face Fit test?',
    name: 'last_face_fit_test',
    required: true,
    conditional: true,
    condition: {
      key: 'role_require_using_a_STIHL_saw',
      value: 'Yes',
    },
  },
  {
    type: 'select',
    label:
      'If applicable would you be willing to clean shaven for a Face Fit test?',
    name: 'clean_shaven_for_a_face_fit_test',
    options: ['Yes', 'No', 'N/A'],
    conditional: true,
    condition: {
      key: 'role_require_using_a_STIHL_saw',
      value: 'Yes',
    },
  },
  {
    type: 'select',
    label: 'Are you in a gang?',
    name: 'are_you_in_a_gang',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have your own transport?',
    name: 'have_your_own_transport',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label: 'Do you have the right to work in the UK?',
    name: 'do_you_have_right_to_work_in_the_uk',
    options: ['Yes', 'No'],
  },
  {
    type: 'select',
    label:
      'Are you able to provide any of the following documentation as proof if your application is successful?',
    name: 'documentation_as_proof',
    options: ['Passport', 'Birth certificate', 'UK working Visa'],
    conditional: true,
    condition: {
      key: 'do_you_have_right_to_work_in_the_uk',
      value: 'Yes',
    },
  },
  {
    type: 'textarea',
    label: 'Please list your previous employers within the past two years',
    name: 'previous_employers',
    required: true,
  },
  {
    type: 'textfield',
    label: 'Please provide your expected daily rate £',
    footnote:
      'This is based being self-employed, a 9 hour shift before any deductions',
    name: 'expected_daily_rate',
    required: true,
    multiline: false,
  },
  {
    type: 'select',
    label: 'Do you have a UTR number (unique taxpayer reference)? ',
    name: 'do_you_have_a_utr_number',
    options: ['Yes', 'No'],
  },
  {
    type: 'date',
    label: 'What date are you available from?',
    name: 'what_date_are_you_available_from',
  },
  {
    type: 'textfield',
    label:
      'Do you know anyone at RM Contractors? If so, please provide their name(s)',
    name: 'do_you_know_anyine_at_rm_contractors',
    required: false,
  },
  {
    type: 'textarea',
    label: 'Is there anything else you would like us to know?',
    name: 'is_there_anything_else_you_would_like_us_to_know',
  },
];

const CssTextField = styled(TextField)({
  width: '100%',
});

const TextFieldItem = props => {
  const { data, values, updateField } = props;
  return (
    <Box>
      <CssTextField
        fullWidth
        required={data.required}
        label={data.label}
        value={values[data.name]}
        onChange={updateField}
        variant="standard"
        name={data.name}
        id={data.name}
        multiline={data.multiline ? true : false}
      />
      {data.footnote && (
        <Box mt={3}>
          <Typography
            component="p"
            variant="caption"
            sx={{ fontWeight: 'bold' }}
          >
            {data.footnote}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SelectFieldItem = props => {
  const { data, values, updateField } = props;
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{data.label}</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={values[data.name]}
        onChange={updateField}
        label="Current Position"
        name={data.name}
      >
        {data.options.map((item, key) => {
          return (
            <MenuItem key={key} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const TextAreaFieldItem = props => {
  const { data, values, updateField } = props;
  return (
    <TextField
      fullWidth
      id="standard-multiline-static"
      label={data.label}
      name={data.name}
      value={values[data.name]}
      onChange={updateField}
      multiline
      rows={4}
      variant="standard"
    />
  );
};

const DatePickerField = props => {
  const { data, values, updateField } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        fullWidth
        label={data.label}
        value={values[data.name]}
        onChange={updateField}
        renderInput={params => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const FileField = props => {
  const { data, values, updateField } = props;
  return (
    <label htmlFor={data.label}>
      <Typography
        sx={{
          fontFamily: 'raisonne-regular-pro',
          color: '#002745',
          fontSize: '1rem',
        }}
      >
        {data.label}
      </Typography>
      <input
        style={{ display: 'none' }}
        id={data.label}
        name={data.name}
        type="file"
        onChange={updateField}
      />
      <Box mt={3}>
        <Button color="secondary" variant="contained" component="span">
          Upload File
        </Button>
      </Box>
    </label>
  );
};

function JobDataBase() {
  const [state, handleSubmit] = useForm('xlezelva');
  const [values, setValues] = useState({});
  const [fileset, setFileSet] = useState('');

  const updateField = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const updateDateField = e => {
    setValues({
      ...values,
      what_date_are_you_available_from: e,
    });
  };

  const fileUploaded = e => {
    if (e.target.value) {
      setFileSet(e.target.value);
    }
  };

  useEffect(() => {
    const initalState = [];
    formItems.map(item => {
      initalState[item.name] = '';
    });
    setValues(initalState);
  }, []);

  if (state.succeeded) {
    return (
      <Box mt={10}>
        <Typography
          component="p"
          variant="h3"
          align="center"
          sx={{
            color: '#002745',
          }}
        >
          Thanks for your enquiry! A member of our team will get back to you as
          soon as possible
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      {formItems.map(item => {
        if (
          item.conditional &&
          item.condition.value !== values[item.condition.key]
        )
          return null;

        switch (item.type) {
          case 'textfield':
            return (
              <Box key={item.name} mb={3}>
                <TextFieldItem
                  data={item}
                  values={values}
                  updateField={updateField}
                />
              </Box>
            );
          case 'textarea':
            return (
              <Box key={item.name} mb={3}>
                <TextAreaFieldItem
                  data={item}
                  values={values}
                  updateField={updateField}
                />
              </Box>
            );
          case 'select':
            return (
              <Box key={item.name} mb={3}>
                <SelectFieldItem
                  data={item}
                  values={values}
                  updateField={updateField}
                />
              </Box>
            );
          case 'date':
            return (
              <Box key={item.name} mt={4} mb={3}>
                <DatePickerField
                  data={item}
                  values={values}
                  updateField={updateDateField}
                />
              </Box>
            );
          case 'file':
            return (
              <Box key={item.name} mt={4} mb={3}>
                <FileField
                  data={item}
                  values={values}
                  updateField={fileUploaded}
                />
                {fileset && (
                  <Box mt={2}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'raisonne-regular-pro',
                        color: 'green',
                        fontSize: '1rem',
                      }}
                    >
                      Thanks! your file{' '}
                      <strong>{fileset.split('\\').pop()}</strong> is uploaded
                      and ready to send
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          default:
            return null;
        }
      })}
      <Box mt={4} display="flex" justifyContent="center">
        <Box
          component="button"
          type="submit"
          disabled={state.submitting}
          px={4}
          py={2}
          sx={{
            border: '1px solid #002745',
            background: '#002745',
            color: '#fff',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
          {state.submitting ? (
            <CircularProgress style={{ color: '#fff' }} />
          ) : (
            'Submit'
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default JobDataBase;
