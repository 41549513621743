const linkResolver = doc => {
  // Pretty URLs for known types
  if (doc.uid === 'home') return '/';
  // Fallback for other types, in case new custom types get created
  if (doc.uid) {
    return `/${doc.uid}`;
  }
  if (doc.url) {
    return `/${doc.url}`;
  }
  if (
    doc.document &&
    doc.document.data &&
    doc.document.data.is_homepage === true
  ) {
    return '/';
  }
  return '/';
};

module.exports = linkResolver;
